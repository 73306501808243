
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BModal, BFormInput } from 'bootstrap-vue';
  import VueRecaptcha from 'vue-recaptcha';

  @Component({
    components: {
      VueRecaptcha,
    },
  })
  export default class LoginModal extends Vue {
    @Ref() readonly modal!: BModal;

    @Ref() readonly rePassword!: BFormInput;

    form = {
      username: '',
      password: '',
    };

    errorMessage = '';

    loading = false;

    async login() {
      if (this.form.username && this.form.password) {
        this.loading = true;
        const data = await this.$vxm.user.login({
          username: this.form.username,
          password: this.form.password,
        });
        this.loading = false;
        if (data.success) {
          this.form.username = '';
          this.modal.hide();
        } else {
          this.errorMessage = data.error;
        }
      }
    }

    onShown() {
      this.errorMessage = '';
      this.form.username = '';
      this.form.password = '';
    }
  }
