
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import NavbarIcons from '../Icons/NavbarIcons.vue';
  import NavbarMenuItem from './NavbarMenuItem.vue';
  import LoginRow from './Login/LoginRow.vue';

  @Component({
    components: {
      NavbarIcons,
      NavbarMenuItem,
      LoginRow
    },
  })
  export default class NavbarCollapseContent extends Vue {
    @Prop({ required: true }) menu!: {
      [text: string]: { [text: string]: string } | string;
    };

    get loggedIn() {
      return this.$vxm.user.loggedIn;
    }
  }
