
  import { Component, Vue } from 'vue-property-decorator';
  import VueBootstrapBreakpointIndicator from 'vue-bootstrap-breakpoint-indicator';
  import Navbar from '@/components/PageLayout/Navbar/Navbar.vue';
  import Modals from '@/components/Modals/Modals.vue';

  @Component({
    components: {
      Navbar,
      Modals,
      VueBootstrapBreakpointIndicator,
    },
  })
  export default class App extends Vue {
    get isDevelopment() {
      return process.env.NODE_ENV !== 'production';
    }
  }
