import { render, staticRenderFns } from "./ResetPasswordModal.vue?vue&type=template&id=ca272cf4&scoped=true"
import script from "./ResetPasswordModal.vue?vue&type=script&lang=ts"
export * from "./ResetPasswordModal.vue?vue&type=script&lang=ts"
import style0 from "./ResetPasswordModal.vue?vue&type=style&index=0&id=ca272cf4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca272cf4",
  null
  
)

export default component.exports