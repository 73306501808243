
  import { Component, Vue } from 'vue-property-decorator';
  import NavbarIcon from './NavbarIcon.vue';

  @Component({
    components: { NavbarIcon },
  })
  export default class UserIcon extends Vue {
    logout() {
      this.$vxm.user.logout();
    }

    get username() {
      return this.$vxm.user.username;
    }
  }
