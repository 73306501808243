
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Utils from '@/utils/utils';

  @Component({})
  export default class BaseNotification extends Vue {
    @Prop({ required: true }) readonly to!: string;

    @Prop() readonly avatar?: string;

    @Prop() readonly prefix?: string;

    @Prop({ required: true }) readonly content!: string;

    strippedBody = Utils.strippedBody;

    isLinkInternal = Utils.isLinkInternal;
  }
