
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BModal } from 'bootstrap-vue';
  import axios from 'axios';
  import TermsAndConditionsText from '@/views/terms/TermsAndConditionsText.vue';

  const ROUTE = '/post/';

  @Component({
    components: { TermsAndConditionsText },
  })
  export default class AcceptTermsModal extends Vue {
    @Ref() readonly modal!: BModal;

    accepted: boolean = false;

    get shown() {
      return this.$vxm.user.userDetailsLoaded && !this.$vxm.user.surveyRecord.match(/EULA_AGREE_2023-06-28/i);
    }

    handleHide(e: Event) {
      if (!this.accepted) e.preventDefault();
    }

    async acceptTerms() {
      if (this.accepted) {
        // Note: $vxm.user.surveyRecord won't update until the next page RELOAD, since that's the
        // only time it gets set. This should be fine though, as we only open this modal on mounted
        // and that happens at page load
        await axios.post(
          ROUTE,
          new URLSearchParams({
            type: 'survey',
            action: 'update',
            value: 'EULA_AGREE_2023-06-28',
            uid: String(this.$vxm.user.uid),
          }),
        );
        // To reload user profile
        await this.$vxm.user.authenticate();
      }
    }
  }
