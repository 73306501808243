
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BModal } from 'bootstrap-vue';

  @Component({
    components: {},
  })
  export default class ResetCompleteModal extends Vue {
    errorMessage: string = '';

    @Ref() readonly modal!: BModal;

    created() {
      this.$vxm.user.$subscribe('showResetCompleteModal', () => {
        this.modal.show();
      });
    }

    hideModal() {
      this.modal.hide();
    }
  }
