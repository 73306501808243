
  import { Component, PropSync, Vue, Watch, Ref } from 'vue-property-decorator';

  @Component({
    components: {},
  })
  export default class MobileSidebar extends Vue {
    @Ref() mobileSidebar!: HTMLDivElement;

    @PropSync('show', {required: true}) isOpen!: boolean;

    mounted() {
      document.addEventListener('click', this.onDocumentClick);
    }

    beforeDestroy() {
      document.removeEventListener('click', this.onDocumentClick);
    }

    onDocumentClick(event: Event) {
      const element = event.srcElement as HTMLElement;
      if (
        element &&
        !element.classList.contains('sidebar') &&
        !this.mobileSidebar.contains(element)
      ) {
        this.isOpen = false;
      }
    }

    @Watch('$route')
    onRouteChange() {
      this.isOpen = false;
    }
  }
