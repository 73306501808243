
  import { BModal } from 'bootstrap-vue';
  import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
  import { RefreshAchievement } from '@/types/common-types';

  @Component({})
  export default class AchievementModal extends Vue {
    @Ref() readonly modal!: BModal;

    achievement: RefreshAchievement | null = null;

    @Watch('$vxm.user.newAchievements')
    showNewAchievement() {
      if (this.$vxm.user.newAchievements.length) {
        [this.achievement] = this.$vxm.user.newAchievements;
        this.modal.show();
      } else this.achievement = null;
    }

    onHidden() {
      this.$vxm.user.newAchievements.shift();
    }
  }
