
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({
    components: {},
  })
  export default class GalleryColumn extends Vue {
    @Prop({ default: '4' }) sm!: string;

    @Prop({ default: '4' }) md!: string;
  }
