
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BModal } from 'bootstrap-vue';
  import VueRecaptcha from 'vue-recaptcha';

  @Component({
    components: {
      VueRecaptcha,
    },
  })
  export default class ResetPasswordModal extends Vue {
    usernameOrEmail = '';

    captchaResponse = '';

    loading = false;

    errorMessage = '';

    attemptNumber: number = 0;

    @Ref() readonly modal!: BModal;

    async resetPassword() {
      // $('#loader').modal('show');
      this.errorMessage = '';
      this.loading = true;
      const response = await this.$http.post(
        '/login/',
        new URLSearchParams({
          type: 'sendreset',
          resetID: this.usernameOrEmail,
          captchaResponse: this.captchaResponse,
        }),
        {
          headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        },
      );
      this.loading = false;
      // $('#loader').modal('hide');
      const { data } = response;
      if (data.data.success) {
        this.modal.hide();
        this.$vxm.user.showResetCompleteModal();
      } else {
        this.errorMessage = data.data.error;
      }
    }
  }
