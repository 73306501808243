
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';

  const ROUTE = '/post/';

  @Component({})
  export default class AcceptTermsModal extends Vue {
    get shown() {
      if (this.$vxm.user.userDetailsLoaded) {
        if (Date.now() > Date.UTC(2023, 4, 15, 12, 0)) return false;
        const entries = this.$vxm.user.surveyRecord.split(',');
        if (entries.includes('DIV_SURVEY_2023_DISMISSED')) return false;
        const delays = entries
          .filter(entry => entry.startsWith('DIV_SURVEY_2023_DELAY:'))
          .map(entry => parseInt(entry.split(':')[1], 10))
          .filter(delay => !Number.isNaN(delay));
        return Math.max(...delays) < Date.now();
      }
      return false;
    }

    async handleYes() {
      // Note: $vxm.user.surveyRecord won't update until the next page RELOAD, since that's the
      // only time it gets set. This should be fine though, as we only open this modal on mounted
      // and that happens at page load
      await axios.post(
        ROUTE,
        new URLSearchParams({
          type: 'survey',
          action: 'update',
          value: 'DIV_SURVEY_2023_DISMISSED',
          uid: String(this.$vxm.user.uid),
        }),
      );
      // To reload user profile
      await this.$vxm.user.authenticate();
      window.open('https://stanfordmedicine.qualtrics.com/jfe/form/SV_26otHCclO1zC850', '_blank');
    }

    async handleNo() {
      // Note: $vxm.user.surveyRecord won't update until the next page RELOAD, since that's the
      // only time it gets set. This should be fine though, as we only open this modal on mounted
      // and that happens at page load
      await axios.post(
        ROUTE,
        new URLSearchParams({
          type: 'survey',
          action: 'update',
          value: 'DIV_SURVEY_2023_DISMISSED',
          uid: String(this.$vxm.user.uid),
        }),
      );
      // To reload user profile
      await this.$vxm.user.authenticate();
    }

    async handleLater() {
      // Note: $vxm.user.surveyRecord won't update until the next page RELOAD, since that's the
      // only time it gets set. This should be fine though, as we only open this modal on mounted
      // and that happens at page load
      await axios.post(
        ROUTE,
        new URLSearchParams({
          type: 'survey',
          action: 'update',
          value: `DIV_SURVEY_2023_DELAY:${Date.now() + (1000 * 60 * 60 * 24)}`,
          uid: String(this.$vxm.user.uid),
        }),
      );
      // To reload user profile
      await this.$vxm.user.authenticate();
    }
  }
