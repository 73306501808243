
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Utils from '@/utils/utils';

  @Component({
    components: {},
  })
  export default class NavbarMenuItem extends Vue {
    @Prop({ required: true }) readonly text!: string;

    @Prop({ required: true }) readonly value!: string | object;

    nav(link: string): string {
      // Use vue-router for local links, instead of reloading page.
      return Utils.isLinkInternal(link) ? 'to' : 'href';
    }

    isExternal(link: string): boolean {
      return Utils.isExternal(link);
    }

    // only called if typeof this.value === object
    get dropdownEntries() {
      return Object.entries(this.value as object);
    }
  }
