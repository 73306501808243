
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import NavbarIcons from '../Icons/NavbarIcons.vue';
  import SidebarMenuItem from './SidebarMenuItem.vue';
  import LoginRow from '../NavbarMenu/Login/LoginRow.vue';

  @Component({
    components: {
      SidebarMenuItem,
      NavbarIcons,
      LoginRow,
    },
  })
  export default class SidebarMenuContent extends Vue {
    @Prop({ required: true }) menu!: {
      [text: string]: { [text: string]: string } | string;
    };

    get menuEntries() {
      return Object.entries(this.menu);
    }

    get loggedIn() {
      return this.$vxm.user.loggedIn;
    }
  }
