
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import NavbarIcons from './Icons/NavbarIcons.vue';
  import NavbarCollapseContent from './NavbarMenu/NavbarCollapseContent.vue';
  import EternaLogo from './EternaLogo.vue';
  import LoginRow from './NavbarMenu/Login/LoginRow.vue';
  import SidebarMenuContent from './SidebarMenu/SidebarMenuContent.vue';
  import MobileSidebar from '../MobileSidebar.vue';

  @Component({
    components: {
      NavbarIcons,
      LoginRow,
      NavbarCollapseContent,
      EternaLogo,
      SidebarMenuContent,
      MobileSidebar,
    },
  })
  export default class Navbar extends Vue {
    showSidebar = false;

    @Ref() sidebar!: MobileSidebar;

    get loggedIn() {
      return this.$vxm.user.loggedIn;
    }

    menu = {
      home: '/',
      play: {
        puzzles: '/puzzles',
        labs: '/labs',
        challenges: '/challenges',
        collections: '/collections',
        data: '/labs/data-browser/',
        'puzzle-maker': `/puzzles/create`,
        'puzzle-maker-2-states': '/puzzles/create?states=2',
        'puzzle-maker-3-states': '/puzzles/create?states=3',
        scripts: `/scripts`,
      },
      learn: {
        'quick-help': '/help',
        'gamer-guide': '/gamerguide',
        guides: 'http://eternawiki.org/wiki/index.php5/Player-Created_Guides',
        wiki: 'http://eternawiki.org',
      },
      community: {
        leaderboards: '/players',
        eternacon: `/eternacon`,
        calendar: '/calendar',
        forum: 'https://forum.eternagame.org',
        discord: 'https://discord.gg/KYeTwux',
        wiki: 'http://eternawiki.org',
        groups: `/groups`,
        'code-of-conduct': '/conduct',
        epa: `/epa`,
      },
      news: '/news',
      about: {
        overview: '/about',
        publications: '/publications',
        tech: '/tech',
        forEducators: '/edu',
        forResearchers: '/researchers',
        merch: `https://www.redbubble.com/people/eternagame/explore`,
        donate: '/donate',
      },
    };
  }
