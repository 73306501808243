
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BModal, BFormInput } from 'bootstrap-vue';
  import VueRecaptcha from 'vue-recaptcha';

  const INITIAL_FORM = {
    username: '',
    email: '',
    password: '',
    rePassword: '',
  };

  @Component({
    components: {
      VueRecaptcha,
    },
  })
  export default class RegisterModal extends Vue {
    form = INITIAL_FORM;

    loading = false;

    accepted: boolean = false;

    captchaResponse = '';

    errorMessage = '';

    attemptNumber: number = 0;

    @Ref() readonly modal!: BModal;

    @Ref() readonly rePassword!: BFormInput;

    async tryRegister() {
      this.errorMessage = '';
      if (!this.accepted) {
        this.errorMessage = 'register-modal:error-accept-terms';
        return;
      }
      if (this.form.password !== this.form.rePassword) {
        this.errorMessage = 'register-modal:error-password-match';
        return;
      }
      if (this.form.username.includes('@')) {
        this.errorMessage = 'register-modal:error-at';
        return;
      }

      this.loading = true;
      await this.register();
    }

    async register() {
      // $('#loader').modal('show');
      const response = await this.$http.post(
        '/login/',
        new URLSearchParams({
          name: this.form.username,
          pass: this.form.password,
          mail: this.form.email,
          type: 'create',
          captcha_response: this.captchaResponse,
        }),
        {
          headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        },
      );
      const { data } = response;
      if (data.data.success) {
        this.form = INITIAL_FORM;
        await this.login();
      } else {
        this.errorMessage = data.data.error;
        this.attemptNumber += 1;
        this.loading = false;
      }
    }

    async login() {
      if (this.form.username && this.form.password) {
        const data = await this.$vxm.user.login({
          username: this.form.username,
          password: this.form.password,
        });
        if (data.success) {
          this.loading = false;
          this.modal.hide();
        } else {
          this.errorMessage = data.data.error;
        }
      }
    }
  }
