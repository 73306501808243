
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Chat } from 'eterna-chat-wrapper';

  import NavbarIcon from './NavbarIcon.vue';

  @Component({
    components: {
      NavbarIcon,
    },
  })
  export default class ChatIcon extends Vue {
    @Prop({ default: false }) readonly isInSideBar!: boolean;

    show: boolean = false;

    @Ref() readonly chatContainer!: HTMLElement;

    chat!: Chat;

    goToChat() {
      if (this.isInSideBar) {
        this.$router.push('/chat');
      } else {
        this.show = !this.show;
      }
    }

    addChat() {
      this.chat = new Chat({
        container: this.chatContainer,
        username: this.$vxm.user.username ? this.$vxm.user.username : '',
        uid: this.$vxm.user.uid ? this.$vxm.user.uid.toString() : '0',
        onHidden: () => this.$root.$emit('bv::hide::popover', 'chat-container'),
        backgroundColor: 'rgb(0, 22, 55)',
      });
    }
  }
