
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import ChatIcon from './ChatIcon.vue';
  import NotificationIcon from './NotificationIcon/NotificationIcon.vue';
  import UserIcon from './UserIcon.vue';

  @Component({
    components: {
      ChatIcon,
      NotificationIcon,
      UserIcon,
    },
  })
  export default class NavbarIcons extends Vue {
    @Prop({ default: false }) readonly isInSideBar!: boolean;
  }
