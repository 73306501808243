
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Utils from '@/utils/utils';

  @Component({
    components: {},
  })
  export default class SidebarMenuItem extends Vue {
    @Prop({ required: true }) readonly text!: string;

    @Prop({ required: true }) readonly value!: string | object;

    @Prop({ required: true }) readonly accordion!: string;

    @Prop({ required: true }) readonly index!: number;

    contentVisible = false;

    // TODO: Try to dedupe with NavbarMenuItem
    nav(link: string): string {
      // Use vue-router for local links, instead of reloading page.
      return Utils.isLinkInternal(link) ? 'to' : 'href';
    }

    isExternal(link: string): boolean {
      return Utils.isExternal(link);
    }

    get collapseId() {
      return `${this.accordion}-${this.index}`;
    }

    // Only called if the b-collapse exists
    onToggle(visible: boolean) {
      this.contentVisible = visible;
    }
  }
