
  import { Component, Mixins } from 'vue-property-decorator';
  import {
    NotificationItem,
    isDirectedNotificationItem,
    isPMNotiItem,
    NotificationType,
    isCommentNotiItem
  } from '@/types/common-types';
  import FetchMixin from '@/mixins/FetchMixin';
  import NavbarIcon from '../NavbarIcon.vue';
  import NewsNotification from './NewsNotification.vue';
  import PrivateMessageNotification from './PrivateMessageNotification.vue';
  import CommentNotification from './CommentNotification.vue';
  import GroupNotificationItem from './GroupNotification.vue';
  import RewardNotificationItem from './RewardNotification.vue';
  import SimpleLoader from '../../../SimpleLoader.vue';

  const NEWS_FEED_ROUTE = '/get/?type=newsfeed&combined=true&filter=all&cache_bust=2';

  const NUMBER_NOTIFICATIONS_TO_SHOW = 5;

  @Component({
    components: {
      NavbarIcon,
      NewsNotification,
      PrivateMessageNotification,
      CommentNotification,
      GroupNotificationItem,
      RewardNotificationItem,
      SimpleLoader
    },
  })
  export default class NotificationIcon extends Mixins(FetchMixin) {
    private isDropdownShown = false;

    isFetching = false;

    notifications: NotificationItem[] = [];

    checkDataInterval?: number;

    async mounted() {
      this.$vxm.notifications.update();
      this.checkDataInterval = setInterval(() => this.$vxm.notifications.update(), this.$vxm.notifications.REFRESH_FREQUENCY);
    }

    destroyed() {
      clearInterval(this.checkDataInterval);
    }

    async onShown(isShown: boolean) {
      this.isDropdownShown = isShown;
      this.isFetching = true;
      await this.updateDropdownContents();
      this.isFetching = false;

      if (this.isDropdownShown) {
        this.$vxm.notifications.unreadCount = 0;
        await this.$http.post('/post/', new URLSearchParams({ type: 'notification_read' }));
      }
    }

    async updateDropdownContents() {
      const response = await this.$http.get(NEWS_FEED_ROUTE);

      // TODO https://github.com/eternagame/eternagame.org/issues/17 improve typing
      const res = response.data.data.entries as NotificationItem[];

      if (res) {
        this.notifications = res
          .map(this.splitMessageThreads)
          .flat()
          .sort((a, b) => this.getCreated(b) - this.getCreated(a))
          .slice(0, NUMBER_NOTIFICATIONS_TO_SHOW);
      }
    }

    splitMessageThreads(notification: NotificationItem) {
      return isPMNotiItem(notification)
        ? notification.message.map(message => ({...notification, message: [message]}))
        : notification;
    }

    getCreated(notification: NotificationItem) {
      return Number(isDirectedNotificationItem(notification) ? notification.message[0].created : notification.timestamp);
    }

    isNewsItem(notification: NotificationItem) {
      return notification.type === NotificationType.NEWS || notification.type === NotificationType.BLOG;
    }

    isPM(notification: NotificationItem) {
      return isPMNotiItem(notification);
    }

    isComment(notification: NotificationItem) {
      return isCommentNotiItem(notification);
    }

    isGroup(notification: NotificationItem) {
      return isDirectedNotificationItem(notification);
    }

    isReward(notification: NotificationItem) {
      return notification.type === NotificationType.REWARD;
    }

  }
